<template>
  <v-dialog :value="isOpen" persistent max-width="650px">
    <v-card class="pa-5">
      <PasFab class="close-btn">
        <v-icon @click="$emit('close')" class="close-icon">close</v-icon>
      </PasFab>
      <v-flex class="mb-4">
        <pas-page-title class="mb-2">Identification type</pas-page-title>
        <hr />
        <p class="identification-description mb-2 mt-4">
          Select the identification type in order to proceed.
        </p>
      </v-flex>

      <v-layout mt-4 align-center justify-center row fill-height>
        <v-form ref="form" lazy-validation>
          <div class="card-container">
            <v-card class="mt-1 mb-9 card-box dual-source" @click="openDualSourceModal">
              <font-awesome-icon
                class="icon memo-circle-check mb-2"
                :icon="['far', 'memo-circle-check']"
              />
              <h3 class="mb-2">Dual source</h3>
              <div class="d-flex flex-row">
                Your client is identified through 2 pieces of acceptable identification to verify their name, address, and date of birth.
              </div>
            </v-card>
            <v-card
              :class="{ disabled: firm === 'har_fcc' || firm === 'har' }"
              class="mt-1 mb-9 card-box photo-id"
              @click="openPhotoIdModal"
            >
              <font-awesome-icon class="icon id-card mb-2" :icon="['far', 'id-card']" />
              <h3 class="mb-2">Photo Identification</h3>
              <div class="d-flex flex-row">
                Your client is identified through a piece of government-issued photo ID that has been physically examined by someone at your firm.
              </div>
            </v-card>
          </div>
        </v-form>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { modal } from "Services/mixins";

export default {
  name: "EquifaxIdentificationSelectionModal",
  mixins: [modal],
  props: {
    isOpen: Boolean,
    workflowId: Number,
  },
  data() {},
  computed: {
    ...mapGetters(["activeClient", "clientId"]),
    firm() {
      return this.$store.getters.firm;
    },
    clientID() {
      return this.workflow.clientInfo.id;
    },
  },
  methods: {
    openDualSourceModal() {
      // ToDo: open dual-source questionaire component once added
    },
    openPhotoIdModal() {
      // ToDo: open photo-id questionaire component once added
    },
  },
};
</script>

<style scoped>
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #eee;
  margin: 0.5em 0;
  padding: 0;
}

.identification-description {
  font-size: medium;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.card-container .card-box {
  flex: 1 1 calc(50% - 1rem);
  box-sizing: border-box;
  padding: 2em;
  margin-bottom: 0rem !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 10px !important;
  box-shadow: 0px 10px 10px 0px #eee !important;
}

.card-box.dual-source {
  cursor: pointer;

  .icon {
    background-color: var(--primary-light);
  }
}

.card-box.photo-id {
  cursor: pointer;

  .icon {
    background-color: var(--primary-light);
  }
}

.icon {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon.memo-circle-check {
  color: #0b4a7a;
}

.icon.id-card {
  color: #478572;
}
</style>
